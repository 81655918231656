import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import EventCard from "../components/Events/EventCard";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import CarouselPlaceholder from "../images/carousel-placeholder.png";
import BlogPost from "../components/BlogPost";

export default function Initiative({ pageContext }) {
  const {
    initiative,
    initiative: {
      name,
      description,
      gallery,
      related_events,
      related_posts,
      slug,
      subtitle,
    },
  } = pageContext;

  return (
    <div>
      <Navbar />
      <div className="w-full my-5 mb-20">
        <div className="px-10 pb-10">
          <div className="text-2xl sm:text-5xl font-extrabold text-mca-green">
            {initiative.name}
          </div>
          <div className="text-mca-grey-2 font-bold max-w-4xl w-full my-3 text-lg sm:text-2xl">
            {initiative.subtitle}
          </div>
        </div>

        <div>
          <img
            src={
              initiative?.gallery?.length > 0
                ? initiative.gallery[0].image
                : CarouselPlaceholder
            }
            className="w-full h-[65vh] object-cover"
            alt={
              initiative?.gallery?.length > 0
                ? initiative.gallery[0].name
                : "Sample Initiative"
            }
          />
        </div>

        <div className="my-16 sm:my-32 relative max-w-screen-lg w-full mx-auto px-5">
          <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center z-10">
            <StaticImage
              src="../../images/blobs/blob-1.png"
              alt="blob"
              placeholder="blurred"
              height={300}
            />
          </div>

          <div className="relative z-20">
            <div
              className="text-lg sm:text-4xl text-mca-grey font-bold leading-relaxed text-justify"
              style={{ textIndent: "2.3em" }}
            >
              {initiative?.description}
            </div>
          </div>
        </div>

        {initiative?.related_posts?.length > 0 && (
          <div className="my-16 sm:my-20 relative max-w-screen-xl w-full mx-auto px-5">
            <div className="relative z-20">
              <div className="text-xl sm:text-2xl uppercase text-mca-grey font-bold">
                Related Posts
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-x-10 gap-y-10 my-5">
                {initiative.related_posts.map(
                  (
                    {
                      featured_image,
                      read_time,
                      subtitle,
                      thumbnail,
                      title,
                      author,
                      slug,
                    },
                    i
                  ) => (
                    <BlogPost
                      post={{
                        image: featured_image,
                        title: title,
                        readingTime: `${read_time} min read`,
                        author: {
                          name: author.name,
                          image: author.avatar,
                        },
                        slug,
                      }}
                      key={i}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        )}

        {/* {related_events.length > 0 && (
          <div className="my-20 sm:my-16 max-w-screen-xl w-full mx-auto px-5">
            <div className="text-xl sm:text-2xl uppercase text-mca-grey font-bold">
              Related Events
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-10 my-5">
              {related_events.map(({ title, date, featured_image }, i) => (
                <EventCard
                  title={title}
                  date={date}
                  image={featured_image}
                  key={i}
                />
              ))}
            </div>
          </div>
        )} */}
      </div>

      {/*<div className="max-w-4xl mx-auto mb-28 px-5 w-screen overflow-x-hidden">
        <pre>
          {JSON.stringify(
            {
              name,
              description,
              gallery,
              related_posts,
              related_events,
              slug,
              subtitle,
            },
            null,
            2
          )}
        </pre>
      </div>*/}

      <Footer />
    </div>
  );
}
